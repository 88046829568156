const app_model = "prod";
let host = "";

switch (app_model) {
    case "dev":
        host = 'http://127.0.0.1:19912';
        break;
    case "nw":
        host = "http://go.nps.zwkecho.cn";
        break;
    case "prod":
        host = 'https://api.bm.yodoven.com';
        break;
}

let baseurl = host + "/api/v1";
let uploadurl = host + "/api/v1/mis/local-upload";
let wxmploginurl = host + "/api/v1/mp/authcode";


const use_xdebug = false;
const xdebug_port = '';


export {
    baseurl, uploadurl, use_xdebug, xdebug_port, wxmploginurl
};
