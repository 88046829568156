
class validation {


	// 判断是否为空
	static Empty(value, msg=undefined) {
		if (value == null || value == undefined || value == "") {
			if (msg) {
				uni.showToast({title:msg,icon:"none"})
			}
			return true;
		}
		return false;
	}


	// 判断是否为数组
	static Is_array(value){
		if (typeof Array.isArray === "function") {
			return Array.isArray(value);
		} else {
			return Object.prototype.toString.call(value) === "[object Array]";
		}
	}

	// 判断是否为json字符串
	static Is_jsonstr(value){
		if (typeof value == 'string') {
			try {
				const obj = JSON.parse(value);
				if (typeof obj == 'object' && obj) {
					return true;
				} else {
					return false;
				}
			} catch (e) {
				return false;
			}
		}
		return false;
	}

	// 判断数据是否为对象
	static Is_obj(value){
		return Object.prototype.toString.call(value) === '[object Object]';
	}

	// 判断是否为身份证号码
	static Is_idcard(value){
		return /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(
			value)
	}


	// 是否为网址
	static Is_url(value){
		return /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w-.\/?%&=]*)?/.test(value)
	}

	// 是否为手机号
	static Is_mobile(value){
		return /^1[23456789]\d{9}$/.test(value)
	}

	// 是否为邮箱
	static Is_email(value){
		return /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/.test(value);
	}

}


export default validation