import {baseurl, uploadurl} from "../config";
import store from "../store";


class requests {


	_baseurl = "";
	_header = {};
	_params = {};
	_timeout = 10000;
	_showbackmsg = false;


	/**
	 * 构造函数
	 * @param _baseurl
	 */
	constructor(_baseurl = undefined) {
		if (_baseurl) {
			this._baseurl = _baseurl;
		} else {
			this._baseurl = baseurl;
		}
		this._header["Authorization"] = store.state.token;

	}

	SetBackNoticeShow() {
		this._showbackmsg = true;
		return this;
	}

	/**
	 * 设置请求header头
	 * @param header object
	 * @returns {requests}
	 * @constructor
	 */
	SetHeader(header = {}) {
		this._header = {...this._header, ...header};
		return this;
	}


	/**
	 * 设置超时时间
	 * @param  timeout int (秒)
	 * @returns {requests}
	 * @constructor
	 */
	SetTimeout(timeout = 10) {
		this._timeout = timeout * 1000;
		return this;
	}


	SetParams(params = {}) {
		this._params = params;
		return this;
	}


	/**
	 * Get 请求
	 * @param url
	 * @param params
	 * @param showbackmsg
	 * @constructor
	 */
	Get(url) {
		return this._send(url, "GET");
	}


	/**
	 * post 请求
	 * @param url 接口地址
	 * @param data 请求参数
	 * @constructor
	 */
	Post(url) {
		return this._send(url, "POST");
	}


	/**
	 * 发送请求
	 * @param url
	 * @param data
	 * @param method
	 * @returns {Promise<unknown>}
	 * @private
	 */
	_send(url, method) {
		const t = this;
		return new Promise((resolve, reject, showbackmsg = true) => {
			uni.request({
				url: `${t._baseurl}${url}`,
				method: method,
				data: t._params,
				header: t._header,
				timeout: t._timeout,
				success: function (ret) {
					ret = t._parseBackResponse(ret);
					if (ret[0]) {
						resolve(ret[1]);
					}
				},
				fail: function (err) {
					uni.showToast({
						title: "网络开小差了",
						icon: "none"
					});
					console.error("request err", err);
				}
			});
		});
	}

	// 处理返回的数据， 拦截特殊状态码
	_parseBackResponse(data = {}) {
		var newdata = {};
		if (data.statusCode !== 200) {
			uni.showToast({
				title: '服务异常， 请稍后再试',
				icon: "none"
			});
			return [false, undefined];
		}

		newdata = data.data;
		var serverCode = newdata.code;
		switch (serverCode) {
			case 401:
				uni.showToast({
					title: "该服务需要登录",
					icon: "none"
				});
				uni.reLaunch({
					url: '/pages/login/login'
				});
				return [false, newdata];
			default:
				return [true, newdata];
		}


	}


	UploadFile(files, formData = {}) {
		return new Promise((resolve) => {
			uni.uploadFile({
				url: uploadurl,
				filePath: files[0],
				name: 'file',
				formData: formData,
				success: (uploadFileRes) => {
					let resdata = JSON.parse(uploadFileRes.data);
					if (resdata.code === 1) {
						resolve(resdata.data)
					}
				}
			});
		});

	}


}


export default requests;