import {createStore} from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
    state: {
        is_login: false,
        token: '---1',
        mobile:"",
        lden:"",
        openid: "",
        user: {
            mobile: "",
            nickname: "",
            id: ""
        },
        wechat: {
            openid: "",
            session_key: "",
        }
    },
    mutations: {
        updateLden(state, lden) {
            state.lden = lden;
        },
        updateOpenid(state, openid) {
            state.openid = openid;
        },
        updateMobile(state, mobile) {
            state.mobile = mobile;
        },
        updateIsLogin(state, is_login) {
            state.is_login = is_login
        },
        updateToken(state, token) {
            state.token = token
        },
        updateUser(state, user) {
            state.user = user
        },
        updateWechat(state, wechat) {
            state.wechat = wechat
        }
    },
    plugins: [
        createPersistedState({
            key: 'miniapp_cache',
            paths: ['user', 'token', 'is_login', 'wechat', 'mobile', 'openid', 'lden'],
            storage: {  // 存储方式定义
                getItem: (key) => uni.getStorageSync(key), // 获取
                setItem: (key, value) => uni.setStorageSync(key, value), // 存储
                removeItem: (key) => uni.removeStorageSync(key) // 删除
            }
        })
    ]
})
