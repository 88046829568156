import {
	createSSRApp
} from "vue";
import store from './store/index'
import uView from 'vk-uview-ui';
import VConsole from 'vconsole';
import App from "./App.vue";

// const vConsole = new VConsole();


export function createApp() {
	const app = createSSRApp(App);
	app.use(store);
	app.use(uView);
	// app.use(vConsole)
	return {
		app,
	};
}
