<script setup>
import {onLaunch, onPageNotFound, onShow} from "@dcloudio/uni-app";
import {useStore} from "vuex";
import validation from "./utils/validation";
import requests from "./utils/request";

const store = useStore();

onLaunch(() => {

	let platform=uni.getSystemInfoSync().platform
	if (platform == "ios") {
		uni.setStorageSync("ios_link", window.location.href)
	}

	// #ifdef MP-WEIXIN
	// #endif
});

onShow(() => {
	const openid = store.state.openid;
	if (validation.Empty(openid)) {
		new requests().Get("/mp/authcode").then(res=> {
			let link = res.data.link;
			let nowLink = window.location.href
			if (nowLink.indexOf("st?openid") === -1) {
				uni.setStorageSync("nowLink", nowLink)
				window.location.href = link;
			}

		})
	}
});

onPageNotFound(() => {
	console.log('页面不存在');
});

</script>

<style lang="scss">
@import "vk-uview-ui/index.scss";
/*每个页面公共css */


page {

	-webkit-touch-callout: none !important;
	-webkit-user-select: none;

	image {
		width: 100%;
		height: 100%;
	}

	.button_none {
		button::after {
			border: none;
		}

		button::after {
			border: 0;
		}

		// 禁用时样式
		button[disabled] {
			background-color: rgba(0, 0, 0, 0.0);
			color: #666;
		}

		button {
			position: relative;
			display: block;
			margin-left: auto;
			margin-right: auto;
			padding-left: 0px;
			padding-right: 0px;
			box-sizing: border-box;
			// font-size: 18px;
			text-align: center;
			text-decoration: none;
			// line-height: 1;
			line-height: 1.35;
			// border-radius: 5px;
			-webkit-tap-highlight-color: transparent;
			overflow: hidden;
			color: #000000;
			background-color: rgba(0, 0, 0, 0.0);;
			width: 100%;
			height: 100%;
		}
	}
}

.float_left {
	float: left;
}

.clearfloat {
	clear: both;
}

.ycyc {
	overflow: hidden; //超出的文本隐藏
	text-overflow: ellipsis; //溢出用省略号显示
	white-space: nowrap; //溢出不换行
}

</style>
